.container {
  width: 100%;
  max-width: 920px;
  margin: 0 auto;
  min-height: 100vh;
  background-color: #f9fafb;
  box-sizing: border-box;
}
.header {
  border: 1px solid #eaecf0;
  border-radius: 12px;
  padding: 16px;
  box-sizing: border-box;
  background-color: #fff;
  width: 100%;
  box-shadow: 0px 1px 2px 0px #1018281a, 0px 1px 3px 0px #1018281a;
}

.card {
  display: flex;
  align-items: start;
  justify-content: space-between;
  width: 100%;
}

.hotelName {
  font-weight: 600;
  color: #101828;
  margin: 0;
}

.hotelAddress {
  font-size: 16px;
  color: #667085;
}

.contentWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
}

.leftColumn {
  flex: 1 1 450px;
}

.rightColumn {
  flex: 1 1 450px;
}

.backButton {
  text-transform: none !important;
  color: #475467 !important;
  font-weight: 500 !important;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  column-gap: 4px;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
}

.addHotelButton {
  background-color: #039855 !important;
  color: #fff !important;
  text-transform: none !important;
  border-radius: 8px !important;
  font-weight: 600 !important;

  &:hover {
    background: #067647;
  }
}
