.audio {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 6px;
    background-color: #FEF3F2;
    border-radius: 12px;
    width: 100%;
    margin-left: 8px;
    column-gap: 6px;


    .control {
        cursor: pointer;
        display: flex;
    }
}

.audiomessage {
    display: flex;
    align-items: flex-end;
    column-gap: 12px;
    padding: 10px 14px;
    background-color: #FEF3F2;
    border-radius: 12px;
    width: 100%;
    min-width: 280px;


    .control {
        cursor: pointer;
        display: flex;
    }

    .content {
        display: flex;
        flex-direction: column;
        row-gap: 2px;
        width: 100%;

        p {
            font-size: 10px;
            font-weight: 500;
            line-height: 18px;
            text-align: left;
            color: #98A2B3;
        }
    }
}

.playButton {
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    font-size: 18px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background-color: #45a049;
    }
}

.seekBar {
    flex-grow: 1;
    margin: 0 4px;
    cursor: pointer;
    padding: 0;

    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 15px;
        height: 15px;
        background-color: #4CAF50;
        border-radius: 50%;
    }

    &::-moz-range-thumb {
        width: 15px;
        height: 15px;
        background-color: #4CAF50;
        border-radius: 50%;
    }
}


.MuiLinearProgress-root {
    cursor: pointer;
    height: 8px;
    border-radius: 5px;
}

.MuiLinearProgress-bar {
    background-color: #4CAF50;
}

.time {
    display: flex;
    align-items: center;

    p {
        font-size: 10px;
        font-weight: 500;
        line-height: 18px;
        text-align: left;
        color: #F97066;
    }
}