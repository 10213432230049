.field {
  display: flex;
  flex-direction: column;
  width: 32%;
  gap: 8px;

  .label {
    padding: 0;
    margin: 0;
  }

  .input {
    background-color: #fff;
  }
}
