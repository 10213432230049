#note-menu {
    .MuiPaper-root {
        min-width: 324px;
        border-radius: 12px;
        box-shadow: 0px 8px 8px -4px #10182808, 0px 20px 24px -4px #10182814 !important;
        border: 1px solid #EAECF0;
    }

    .MuiList-root {
        padding: 0;
    }
}