.chat {
    padding: 16px;
    display: flex;
    align-items: center;
    height: calc(100vh - 55px);

    .body {
        display: flex;
        align-items: center;
        background: #F8F8F8;
    }
}