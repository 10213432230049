.infoCard {
  border: 1px solid #eaecf0;
  border-radius: 12px;
  padding: 16px;
  box-sizing: border-box;
  background-color: #fff;
  width: 100%;
  box-shadow: 0px 1px 2px 0px #1018281a, 0px 1px 3px 0px #1018281a;
  margin-top: 20px;
}

.title {
  font-size: 16px;
  font-weight: 600;
  color: #101828;
}
.icon {
  border: 1px solid #e4e7ec;
  padding: 6px;
  border-radius: 6px;
  box-shadow: 0px 1px 2px 0px #1018280d, 0px -2px 0px 0px #1018280d inset,
    0px 0px 0px 1px #1018280d inset;
}

.text {
  font-size: 14px;
  line-height: 20px;
  color: #101828;
  margin-top: 12px;
  max-height: 200px;
  overflow: auto;
  cursor: all-scroll;
}
