.sidebar {
    width: 100%;
    min-width: 320px;
    max-width: 320px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    // gap: 16px;
    height: 100%;
    overflow: auto;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;


    &::-webkit-scrollbar {
        display: none;
    }
}

.close_icon {
    cursor: pointer;
}

.loader {
    display: flex;
    align-items: center;
    padding: 8px 0;
    justify-content: center;
}

.search {
    display: flex;
    justify-content: center;
    background: #fff;
    position: sticky;
    top: 0;
    margin-bottom: 16px;
    z-index: 1;

    fieldset {
        border-bottom: 1px solid #EAECF0 !important;
        border: none;
        border-radius: 0;

        &:focus-visible {
            outline: none;
        }
    }

    input {
        width: 90%;
        padding: 16px;
        border: none;
        border-radius: 0;

        &:focus-visible {
            outline: none;
        }
    }
}




















.date_group {
    padding: 0 8px;

    .group_box {
        // max-height: 320px;
        overflow: auto;

        &::-webkit-scrollbar {
            width: 8px;
            border-radius: 8px;
        }

        &::-webkit-scrollbar-track {
            background: #fff;
        }

        &::-webkit-scrollbar-thumb {
            background: #EAECF0;
            border-radius: 8px;
        }

    }
}





.date {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: #475467;
    margin: 0 8px;
    margin-bottom: 4px;
}

.user {
    display: flex;
    flex-direction: column;
    padding: 10px;
    border-radius: 10px;
    background-color: #fff;
    margin-bottom: 10px;
    gap: 10px;
    transition: .4s;
    border-radius: 8px;
    cursor: pointer;

    &:hover {
        background: #F2F4F7;
    }

    &.active {
        background: #F2F4F7 !important;
    }
}


.userInfo {
    display: flex;
    column-gap: 8px;
    align-items: start;
}

.avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}

.details {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.userName {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: #344054;
}

.platform {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #475467;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 180px;
}

.status {
    margin-top: 5px;
    background: #FEF3F2;
    padding: 4px 12px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    column-gap: 6px;
    width: fit-content;

    .status_text {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        text-align: center;
        color: #B42318;
    }
}

.unassigned {
    margin-top: 5px;
    background: #EEF4FF;
    padding: 4px 12px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    column-gap: 6px;
    width: fit-content;

    .dot {
        border-radius: 50%;
        width: 6px;
        height: 6px;
        background: #6172F3;
        left: 6px;
    }

    .unassigned_text {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        text-align: center;
        color: #3538CD;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 110px;
    }
}

.assigned {
    margin-top: 5px;
    background: #FEF3F2;
    padding: 4px 10px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    column-gap: 6px;
    width: fit-content;

    .dot {
        border-radius: 50%;
        width: 6px !important;
        height: 6px;
        background: #B42318;
        left: 6px;
    }

    .assigned_text {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        text-align: center;
        color: #B42318;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 150px;
        text-align: left;
    }
}

.unreadTime {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    row-gap: 4px;
}

.loader_wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.unread {
    background-color: #D92D20;
    color: #fff;
    padding: 2px 8px;
    border-radius: 44px;
    font-size: 12px;
    font-weight: 400;
    line-height: var(--M);
    text-align: center;
}

.time {
    font-size: 10px;
    font-weight: 400;
    line-height: var(--L);
    text-align: left;
    color: #475467;
}