.infoCard {
  border: 1px solid #eaecf0;
  border-radius: 12px;
  padding: 16px;
  box-sizing: border-box;
  background-color: #fff;
  width: 100%;
  box-shadow: 0px 1px 2px 0px #1018281a, 0px 1px 3px 0px #1018281a;
  margin-top: 20px;
}

.title {
  font-size: 16px;
  font-weight: 600;
  color: #101828;
  padding-bottom: 12px;
  border-bottom: 1px solid #e8e8e8;
}
.input {
  background: #fff;
  margin-top: 12px;

  input {
    background: #fff;
  }
}
