.wrapper {
  border-radius: 8px;
  background: #f9f9f9;
  padding: 24px 16px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 600px) {
    flex-direction: column;
    gap: 20px;
  }

  .contact {
    width: 49%;

    @media (max-width: 600px) {
      width: 100%;
    }
  }
}
