.mock {
    background: #fff;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    border-left: 1px solid #EAECF0;
    justify-content: center;

    .box {
        box-shadow: 0px 1px 2px 0px #1018280F, 0px 1px 3px 0px #1018281A;
        background: #FFFFFF;
        border-radius: 24px;
        padding: 32px 54px;
        display: flex;
        flex-direction: column;
        row-gap: 8px;
        align-items: center;
    }
}