.skeleton {
    margin-bottom: 10px;
    border-radius: 8px;
}

.flex {
    display: flex;
    align-items: center;
    column-gap: 16px;
}

.field_skeleton {
    padding: 8px 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 0;
    }
}