.title {
  font-size: 20px;
  font-weight: 500;
  line-height: 23.44px;
  color: #00040a;
  margin-bottom: 12px !important;
}

.grid {
  width: 100% !important;
  display: flex;
  align-items: center;
  column-gap: 16px;

  @media (max-width: 992px) {
    flex-direction: column;
    row-gap: 12px;
  }
}

.card {
  border-radius: 16px;
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: row;
  min-height: 240px;
  @media (max-width: 992px) {
    max-height: 240px;
  }
  @media (max-width: 566px) {
    min-height: 100%;
    max-height: 100%;
    flex-direction: column;
  }
}

.imageWrapper {
  width: 50%;
  overflow: hidden;

  @media (max-width: 566px) {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;

  @media (max-width: 566px) {
    max-height: 200px;
  }
}

.content {
  width: 50%;
  padding: 16px 24px !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 566px) {
    width: 100%;
    padding: 16px !important;
  }

  .content_title {
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    color: #101828;
    margin-bottom: 8px;
  }

  .description {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #475467;
  }
}

.addButton {
  background: #fff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px 0px #1018280d;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #344054;
  border-radius: 8px;
  display: flex;
  align-items: center;
  column-gap: 8px;
  pointer-events: none;

  @media (max-width: 566px) {
    margin-top: 24px !important;
  }

  &.active {
    background: #039855;
    color: #fff;
    border: none;
  }
}
