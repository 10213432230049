.details {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  gap: 10px;

  .paymentInformation {
    border-bottom: 1px solid rgba(229, 233, 235, 1);
    padding-bottom: 16px;
    margin-bottom: 16px;

    h4 {
      font-size: 14px;
      font-weight: 600;
      line-height: 19px;
      margin-bottom: 15px;
    }

    .box {
      margin-bottom: 8px;
      .field {
        display: flex;
        background-color: #fff;
        width: 40%;
      }
    }
  }

  .box {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 10px;
    margin-bottom: 4px;

    p {
      width: 40%;
      font-size: 12px;
      font-weight: 500;
      line-height: 24px;
    }
    .package_text {
      width: 100%;
      max-width: 16.2%;
      font-size: 12px;
      font-weight: 500;
      line-height: 24px;
    }

    .field {
      display: flex;
      background-color: #fff;
      width: calc(80% + 12px);
    }
    .package {
      display: flex;
      background-color: #fff;
      width: 100%;
      max-width: 16.2%;
    }
  }
}
