.missed_call {
    display: flex;
    align-items: start;
    column-gap: 16px;
    padding: 10px 14px;
    border-radius: 8px;
    background: #FEF3F2;
    width: 100%;
    height: 100%;
    min-width: 272px;
    min-height: 60px;

    .icon {
        padding: 6px;
        width: 32px;
        height: 32px;
        border-radius: 6px;
        background: #FEE4E2;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .content {
        p {
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            text-align: left;
            color: #B42318;

        }

        .date {
            display: flex;
            align-items: center;
            column-gap: 4px;

            p {
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                text-align: left;
                color: #D92D20;
            }
        }
    }
}

.call {
    display: flex;
    align-items: start;
    column-gap: 16px;
    padding: 10px 14px;
    border-radius: 8px;
    background: #FEF3F2;
    width: 100%;
    height: 100%;
    min-width: 272px;
    min-height: 60px;

    .icon {
        padding: 6px;
        width: 32px;
        height: 32px;
        border-radius: 6px;
        background: #FEE4E2;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .content {
        p {
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            text-align: left;
            color: #B42318;

        }

        .date {
            display: flex;
            align-items: center;
            column-gap: 4px;

            p {
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                text-align: left;
                color: #039855;
            }
        }
    }
}