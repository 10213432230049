.card {
    background-color: #F9FAFB;
    border-radius: 12px;
    box-shadow: 0px 1px 1px 0px #0000000F;
    transition: max-height 0.3s ease-in-out;
    padding: 6px 12px;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;
}

.open {
    max-height: 200px;
}

.header {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    align-items: center;

    .order {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        text-align: left;
        color: #344054;
    }

    .status {
        background-color: #FEF3F2;
        color: #B42318;
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        text-align: center;
        padding: 2px 8px;
        border-radius: 16px;
    }

}

.main {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    align-items: center;

    .destination {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        text-align: left;
        color: #667085;
    }

    .date {
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        text-align: center;
        color: #344054;
        padding: 2px 8px;
        background: #F2F4F7;
        border-radius: 16px;
    }
}