.section {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0;

  img {
    @media (max-width: 992px) {
      width: 100% !important;
      max-width: 150px !important;
    }
    @media (max-width: 566px) {
      width: 100% !important;
      max-width: 90px !important;
      max-height: 40px;
      object-fit: contain;
    }
  }
}
