.infoCard {
  border: 1px solid #eaecf0;
  border-radius: 12px;
  padding: 16px;
  box-sizing: border-box;
  background-color: #fff;
  width: 100%;
  box-shadow: 0px 1px 2px 0px #1018281a, 0px 1px 3px 0px #1018281a;
}

.title {
  font-size: 16px;
  font-weight: 600;
  color: #101828;
  margin-bottom: 16px;
}

.row {
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
}

.column {
  flex: 1;
}

.label {
  font-size: 14px;
  line-height: 20px;
  color: #667085;
  font-weight: 400;
}

.value {
  color: #101828;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}

.selectField {
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  .MuiOutlinedInput-root {
    border-radius: 8px;
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: #d0d5dd;
  }
}

.icon {
  color: #667085;
  margin-right: 8px;
}

.range {
  margin-bottom: 12px;
  input {
    padding: 16px 0 16px 0;
    font-size: 16px;
    line-height: 24px;
    color: #101828 !important;
    font-weight: 500;
  }

  & > div {
    display: flex;
    flex-direction: row-reverse;
  }

  &.right {
    input {
      padding: 16px 0 16px 16px;
      font-size: 16px;
      line-height: 24px;
      color: #101828 !important;
      font-weight: 500;
    }

    & > div {
      display: flex;
      flex-direction: row !important;
    }
  }
}
