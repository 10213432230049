.menu {
  min-width: 324px !important;

  .header {
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid #eaecf0;

    .content {
      display: flex;
      align-items: center;
      column-gap: 12px;

      .title {
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        text-align: left;
        color: #000;
      }
    }

    .close {
      cursor: pointer;
    }
  }

  .input {
    background-color: #fff;
    width: 100%;
    min-height: 220px;
    border: none;
    padding: 16px;

    &:focus-visible {
      outline: none;
    }

    fieldset {
      border-color: #fff !important;
    }
  }
}
