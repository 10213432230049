.user {
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-radius: 10px;
  background-color: #fff;
  margin-bottom: 10px;
  gap: 10px;
  transition: 0.4s;
  border-radius: 8px;
  margin: 4px 8px;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;

  &:hover {
    background: #f2f4f7;
  }

  &.active {
    background: #f2f4f7 !important;
  }
}

.userInfo {
  display: flex;
  column-gap: 8px;
  align-items: center;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.details {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.userName {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #344054;
}

.platform {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #475467;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 180px;
}
