.box {
    padding: 0 64px;
    height: calc(100vh - 187px);


}

.flexbox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;


}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100vh;
    background-color: #f0f0f0;
}

.error {
    display: flex;
    flex-direction: column;
    width: 100%;

}


.errorCode {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: #6941C6;
}

.message {
    font-size: 60px;
    font-weight: 600;
    line-height: 72px;
    letter-spacing: -0.02em;
    text-align: left;
    margin-top: 12px;
    margin-bottom: 24px;


}

.description {
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
    color: #475467;
    max-width: 480px;


}

.buttons {
    display: flex;
    gap: 12px;
    margin-top: 48px;


}

.homeButton {
    background: #D92D20;
    border: 1px solid #D92D20;
    color: #fff;
    padding: 16px 28px;
    border-radius: 8px;
    cursor: pointer;
    box-shadow: 0px 1px 2px 0px #1018280D;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    text-align: left;
    transition: .3s;


}

.backButton {
    background: #fff !important;
    color: #344054;
    border: 1px solid #D0D5DD;
    column-gap: 6px;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    text-align: left;
    padding: 16px 28px;
    border-radius: 8px;
    cursor: pointer;
    box-shadow: 0px 1px 2px 0px #1018280D;
    display: flex;
    align-items: center;





}

.homeButton:hover {
    background-color: #E31B54;
}

.errorGraphic {
    display: flex;
    align-items: center;
    justify-content: center;


}

.svgText {
    font-family: Arial, sans-serif;
    font-size: 72px;
    fill: #d3d3d3;
}