.menu {
    min-width: 324px !important;

    .header {
        padding: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        border-bottom: 1px solid #EAECF0;

        .content {
            display: flex;
            align-items: center;
            row-gap: 12px;
            flex-direction: column;

            h4 {
                font-size: 14px;
                font-weight: 600;
                line-height: 20px;
                text-align: left;
                color: #000;
            }
        }

        .close {
            cursor: pointer;
        }
    }

    .body {
        display: flex;
        align-items: center;
        column-gap: 12px;
        padding: 24px;
        width: 100%;

        .save_button {
            color: #fff;
            border: 1px solid #FEF3F2;
            background: #D92D20;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            border-radius: 8px;
            width: 100%;
        }

        .cancel_button {
            color: #344054;
            background: #fff;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            border-radius: 8px;
            border: 1px solid #D0D5DD;
            box-shadow: 0px 1px 2px 0px #1018280D;
            width: 100%;
        }
    }

    .date_picker {
        background: #fff;
    }

    .input {
        background-color: #fff;
        width: 100%;
        min-height: 220px;
        border: none;
        padding: 16px;

        &:focus-visible {
            outline: none;
        }


        fieldset {
            border-color: #fff !important;
        }
    }
}