.field {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;

  .label {
    padding: 0;
    margin: 0;
  }

  .input {
    background-color: #fff;
  }

  .img_section {
    display: flex;
    align-items: center;
    column-gap: 10px;

    .upload {
      background: #fcfcfd;
      color: #667085;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      text-align: center;
      display: flex;
      align-items: center;
      flex-direction: column;
      row-gap: 8px;
      border: 1px dashed #667085;
      min-height: 165px;
      width: 100%;
      max-width: 182px;
      border-radius: 12px;
    }

    .image {
      border: 1px solid #ccc;
      min-height: 165px;
      width: 100%;
      max-width: 182px;
      object-fit: cover;
      border-radius: 12px;
      position: relative;
      height: 100%;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: 12px;
      }
    }

    .delete {
      position: absolute;
      top: 5px;
      right: 5px;
      cursor: pointer;
    }
  }
}
