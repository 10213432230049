.passenger {
  display: flex;
  flex-direction: column;
  background-color: #F8F8F8;
  border-radius: 12px;


  .header {
    padding: 12px 16px 12px 16px;
    border-radius: 12px;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;
    border-bottom: 1px solid rgba(237, 237, 237, 1);
    background: rgba(249, 249, 249, 1);

    cursor: pointer;
    outline: none;

    h3 {
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
    }

    .actions {
      display: flex;
      align-items: center;
      gap: 20px;
    }

    .title_box {
      display: flex;
      align-items: center;
      column-gap: 8px;
    }
  }

  .body {
    border-radius: 12px;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    gap: 12px;
    background: rgba(249, 249, 249, 1);
    padding-bottom: 16px;

    .field {
      padding: 12px 16px 0px 16px;
      display: flex;
      flex-direction: column;
      width: calc(33% - 12px);

      p {
        width: 100%;
        font-size: 14px;
        font-weight: 400;
        padding: 0;
        margin: 0;
      }

      .input {
        width: 100%;
        background-color: white;
      }
    }
  }
}

.passenger_buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 12px;

  .exchange_button {
    background: #D92D20;
    padding: 10px 16px;
    border-radius: 8px;
    border: 1px solid #D92D20;
    box-shadow: 0px 1px 2px 0px #1018280D;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
    color: #fff;
  }

  .add_button {
    background: #475467;
    padding: 10px 16px;
    border-radius: 8px;
    border: 1px solid #475467;
    box-shadow: 0px 1px 2px 0px #1018280D;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
    color: #fff;
  }
}