.infoCard {
  border: 1px solid #eaecf0;
  border-radius: 12px;
  padding: 16px;
  box-sizing: border-box;
  background-color: #fff;
  width: 100%;
  box-shadow: 0px 1px 2px 0px #1018281a, 0px 1px 3px 0px #1018281a;
  margin-top: 20px;
}

.title {
  font-size: 16px;
  font-weight: 600;
  color: #101828;
  padding-bottom: 12px;
  border-bottom: 1px solid #e8e8e8;
}

.list {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  height: 100%;
  max-height: 200px;
  overflow: auto;
  margin-top: 12px;
}

.room {
  border: 1px solid #e4e7ec;
  background: #fff;
  border-radius: 12px;
  padding: 16px;
  display: flex;
  align-items: start;
  column-gap: 12px;
  cursor: pointer;

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background: #fff;
    box-shadow: 0px 1px 2px 0px #1018280d, 0px -2px 0px 0px #1018280d inset,
      0px 0px 0px 1px #1018280d inset;
    padding: 10px;
    border-radius: 12px;
    border: 1px solid #e4e7ec;
  }

  h5 {
    color: #101828;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 2px;
  }

  p {
    color: #475467;
    font-weight: 400;
  }
}
