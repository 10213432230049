.table_title {
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
  color: #101828;
}

.filter_button {
  background: #fff;
  border: 1px solid #d0d5dd;
  color: #344054;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  border-radius: 8px;
  display: flex;
  align-items: center;
}

.filters {
  display: flex;
  align-items: center;
  column-gap: 12px;

  .search {
    width: 100% !important;
  }
}

.status {
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;

  .status_box {
    background: #f2f4f7;
    padding: 2px 8px;
    border-radius: 16px;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    text-align: center;
    color: #344054;
    max-width: 65px;

    &.active {
      background: #ecfdf3 !important;
      color: #027a48 !important;
    }
  }
}

.popover_container {
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 2px;
  padding: 6px;

  .wrapper {
    background: #fff;
    width: 100%;

    .button {
      width: 100%;
      border-radius: 6px !important;
      background: #fff;
      color: #475467;
      display: flex;
      align-items: center;
      padding: 6px;
      justify-content: start;
      column-gap: 4px;
      min-width: -webkit-fill-available;
      font-size: 12px;

      &:hover {
        background: #d92d20;
        color: #fff;
      }
    }
  }
}
