.popover_container {
    display: flex;
    align-items: center;
    flex-direction: column;
    row-gap: 2px;
    padding: 6px;

    .wrapper {
        background: #fff;
        width: 100%;

        .button {
            width: 100%;
            border-radius: 6px !important;
            background: #fff;
            color: #475467;
            display: flex;
            align-items: center;
            justify-content: start;
            column-gap: 8px;

            &:hover {
                background: #D92D20;
                color: #fff;

                svg {
                    color: #fff;


                    path {
                        stroke: #fff;
                    }
                }
            }
        }
    }
}