.lead {
    display: flex;
    column-gap: 16px;
    padding-bottom: 60px;
    // position: relative;
    // height: calc(100vh - 144px);

    .backdrop_loader {
        width: 100px !important;
        height: 100px !important;
    }

    .left_side {
        width: 25%;
        height: 100%;

        .lead_info {
            background: #fff;
            border-radius: 8px;
            padding: 16px;
            height: 100%;
            max-height: 80vh;
            overflow: auto;

            // @media (max-width: 1500px) {
            //     max-height: 75vh !important;
            // }



            &::-webkit-scrollbar {
                display: none;
            }
        }
    }

    .save_button {
        border-radius: 8px;
        padding: 8px 24px;
    }

    .right_side {
        display: flex;
        width: 75%;
        flex-direction: column;
        row-gap: 16px;

        .flights_info {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            row-gap: 16px;
            background: #fff;
            padding: 16px;
            border-radius: 8px;

            button {
                border-radius: 8px;
                font-weight: 600;
            }
        }

        .scroll_flights {
            overflow: auto;
            align-items: start !important;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        .flights {
            width: 100%;
            display: flex;
            align-items: center;
            column-gap: 26px;
            row-gap: 16px;
            background: #fff;
            padding: 16px;
            max-height: calc(80vh - 88px);
            border-radius: 8px;



            &::-webkit-scrollbar {
                display: none;
            }

            .segment {
                width: 100%;
                max-width: 240px;


                .accordion_list {
                    display: flex;
                    flex-direction: column;
                    row-gap: 10px;
                    margin-bottom: 10px;

                    .accordion {
                        background: #F8F8F8;
                        border-radius: 12px;
                        box-shadow: none;
                        border: none;
                        margin: 0;

                        &::before {
                            display: none;
                        }
                    }
                }

            }

            .summary {
                padding: 8px;
                border: none;
                min-height: auto;
                height: 100%;

                div {
                    margin: 0;
                }

                p {
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 20px;
                    text-align: left;
                    color: #475467;
                }
            }

            .content {
                padding: 0 8px;
                padding-bottom: 8px;


                .segment_form {
                    display: flex;
                    flex-direction: column;
                    row-gap: 8px;

                    .head {
                        display: flex;
                        align-items: center;
                        column-gap: 8px;


                        button {
                            background: #00BF41;
                            border-radius: 8px;
                        }
                    }

                    .delete_button {
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        column-gap: 4px;
                        color: #B42318;
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 20px;
                        text-align: left;

                    }
                }
            }
        }

        .add_segment_button {
            background: #FF00001A;
            color: #FF0000;
            font-size: 14px;
            font-weight: 600;
            line-height: 16.94px;
            text-align: left;
        }
    }

    .field {
        display: flex;
        flex-direction: column;
        gap: 8px;

    }

    .label {
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
    }

    .input {
        background-color: #fff;
        width: 100%;

        fieldset {

            border-color: #E5E9EB !important;
        }
    }

    .lead_form {
        margin-top: 16px;
        display: flex;
        flex-direction: column;
        row-gap: 12px;

    }

    .lead_footer {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: white;
        padding: 8px 10px;
        box-shadow: 0px 1px 0px 0px #E5E9EB inset;

        .lead_footer_button {
            width: 100%;
            display: flex;
            align-items: center;
            column-gap: 8px;
            max-width: 500px;

            // div {
            //     &:first-child {
            //         max-width: 160px;
            //     }
            // }

            // div {
            //     &:nth-child(2) {
            //         min-width: auto;
            //     }
            // }
        }

        .save_button_box {
            display: flex;
            align-items: center;
            column-gap: 16px;

            .footer_button {
                background: #FEF3F2;
                border: 1px solid #FEF3F2;
                color: #D92D20;
                font-size: 14px;
                font-weight: 600;
                line-height: 20px;
                border-radius: 8px;
            }

            .note_button {
                background: #fff;
                border: 1px solid #D0D5DD;
                color: #344054;
                font-size: 14px;
                font-weight: 600;
                line-height: 20px;
                border-radius: 8px;
                display: flex;
                align-items: center;
                column-gap: 8px;
            }

            .save_button {
                color: #fff;
                border: 1px solid #FEF3F2;
                background: #D92D20;
                font-size: 14px;
                font-weight: 600;
                line-height: 20px;
                border-radius: 8px;
            }
        }



    }

    .scroll_tickets {
        overflow: auto;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .tickets {
        width: 100%;
        position: sticky;
        top: 0;
        height: 100%;

        .selected_flights {
            margin-bottom: 16px;

            .flight_basket {
                display: flex;
                flex-direction: column;
                row-gap: 12px;
                margin-top: 10px;
            }
        }

        .list {
            display: flex;
            flex-direction: column;
            row-gap: 16px;
            height: 100%;
            overflow: auto;
            max-height: 85%;
            margin-top: 10px;

            &::-webkit-scrollbar {
                display: none;
            }

            .loader {
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &::-webkit-scrollbar {
                display: none;
            }
        }

        .head {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .client_mode {
                display: flex;
                align-items: center;
                column-gap: 4px;

                p {
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 20px;
                    text-align: right;
                    color: #344054;
                }
            }

            h5 {
                font-size: 20px;
                font-weight: 500;
                line-height: 23.44px;
            }
        }
    }

    .mock_up {
        // width: 100%;
        height: auto;
        // opacity: 0;
        // padding: 25px;
        position: fixed;
        top: -100%;
        left: -100%;
        // display: none;


        .mock_up_list {
            display: flex;
            flex-direction: column;
            row-gap: 16px;
            height: max-content;
            margin-top: 10px;

            &::-webkit-scrollbar {
                display: none;
            }

            .loader {
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &::-webkit-scrollbar {
                display: none;
            }
        }

        .head {
            display: flex;
            align-items: center;
            justify-content: space-between;

            h5 {
                font-size: 20px;
                font-weight: 500;
                line-height: 23.44px;
            }
        }
    }

    .accordion_list {
        display: flex;
        flex-direction: column;
        row-gap: 10px;

        .accordion {
            background: #F8F8F8;
            border-radius: 12px;
            box-shadow: none;
            border: none;
            margin: 0;

            &::before {
                display: none;
            }
        }
    }
}

.item {
    color: #fa050a;
    display: flex;
    align-items: center;
    column-gap: 8px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
}