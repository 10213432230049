.contact_box {
    padding: 0 8px 16px 8px;

    .contact {
        padding: 10px;
        display: flex;
        align-items: center;
        column-gap: 8px;
        border-radius: 8px;
        transition: .4s;
        cursor: pointer;

        &:hover {
            background: #F9FAFB;
        }

        .name {
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            text-align: left;
            color: #101828;
        }

        .username {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            text-align: left;
            color: #475467;
        }
    }
}