.section {
  width: 100%;

  .section_title {
    font-size: 20px;
    font-weight: 500;
    line-height: 23.44px;
    color: #00040a;
    margin-bottom: 12px;
  }

  .grid {
    display: grid;
    gap: 12px;
    grid-template-columns: 73.3% 25.5%;

    @media (max-width: 992px) {
      display: flex;
      flex-direction: column;
    }
  }

  .card {
    background: #fff;
    box-shadow: 0px 1px 2px 0px #1018280f, 0px 1px 3px 0px #1018281a;
    border-radius: 8px;
    grid-column: 1 / 2;
    grid-row: 1;
  }

  .hotel_header {
    display: flex;
    align-items: center;
    column-gap: 16px;
    border-bottom: 1px solid #e8e8e8;
    padding: 16px;

    .hotel_key {
      font-size: 12px;
      line-height: 18px;
      color: #667085;
      display: block;
      @media (max-width: 992px) {
        display: none;
      }
    }
    .rating {
      display: none;
      @media (max-width: 992px) {
        display: flex;
      }
    }
  }

  .hotel_footer {
    padding: 16px;
  }

  .iconWrapper {
    width: 48px;
    height: 48px;
    border: 1px solid #eaecf0;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
  }

  .hotel_rating {
    margin-left: auto;
    span {
      font-size: 16px;
    }
  }

  .hotel_body {
    border-bottom: 1px solid #e8e8e8;
    padding: 16px;
  }

  .hotel_desc p {
    font-size: 12px;
    line-height: 18px;
    color: #667085;
  }

  .hotel_desc h5 {
    font-size: 14px;
    line-height: 20px;
    color: #101828;
    font-weight: 500;
  }

  .key {
    font-size: 12px;
    line-height: 18px;
    color: #667085;
  }
  .value {
    font-size: 14px;
    line-height: 20px;
    color: #101828;
    font-weight: 500;
  }

  .data_card {
    display: flex;
    align-items: start;
    flex-wrap: wrap;
    gap: 16px;

    .data_item {
      width: 100%;
      display: flex;
      flex-direction: column;
      max-width: 159.5px;
      height: 100%;

      @media (max-width: 992px) {
        max-width: 150px;
      }
      @media (max-width: 556px) {
        max-width: 130px;
      }

      .data_key {
        font-size: 12px;
        line-height: 18px;
        color: #667085;
      }

      .data_value {
        font-size: 14px;
        line-height: 20px;
        color: #101828;
        font-weight: 500;
        max-width: 100px;
      }
    }
  }

  .sidebar {
    background: #fff;
    box-shadow: 0px 1px 2px 0px #1018280f, 0px 1px 3px 0px #1018281a;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    grid-column: 2;
    grid-row: 1 / 3;

    .rating {
      padding: 16px;
      display: flex;
      align-items: center;
      column-gap: 16px;
      border-bottom: 1px solid #e8e8e8;
      @media (max-width: 992px) {
        display: none;
      }

      .key {
        font-size: 12px;
        line-height: 18px;
        color: #667085;
      }
    }

    .options {
      padding: 16px;

      .key {
        font-size: 12px;
        line-height: 18px;
        color: #667085;
      }

      ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
          font-size: 14px;
          color: #101828;
          font-weight: 500;
          display: flex;
          align-items: center;
          margin-top: 8px;

          img {
            margin-right: 12px;
            width: 32px;
            height: 32px;
            object-fit: cover;
            border-radius: 6px;
          }
        }
      }
    }
  }

  .policies {
    background: #fff;
    box-shadow: 0px 1px 2px 0px #1018280f, 0px 1px 3px 0px #1018281a;
    border-radius: 8px;
    padding: 16px;
    grid-column: 1 / 2;
    grid-row: 2;

    .fixed_desc {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      -webkit-line-clamp: 2;
    }

    .desc {
      max-height: 100%;
    }

    .key {
      font-size: 12px;
      line-height: 18px;
      color: #667085;
    }

    .value {
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      color: #101828;
      max-height: 100%;
      word-break: break-all;
    }

    a {
      color: #475467;
      text-decoration: underline;
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
    }
  }
}
