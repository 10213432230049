.row {
    display: flex;
    align-items: center;
    width: 100%;
    // max-width: 700px;

    .label {
        width: 150px;
        transform: translateY(-13px);
        font-size: 14px;
        font-weight: 500 !important;
        line-height: 24px;
        text-align: left;
        color: #303940;


        .requiredStart {
            color: red;
        }

        .labelInput {
            border: none;
            outline: none;
            min-width: 10px;
            width: auto;
            font-weight: bold;
            font-size: 13px;
        }
    }

    .component {
        flex: 1;
        padding-left: 30px;
    }

    .desc {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .extra {
            transform: translateY(0);
            margin-bottom: 10px;
            font-weight: bold;
        }
    }

    &.vertical {
        flex-direction: column;
        align-items: flex-start;

        .label {
            width: auto;
            transform: translateY(0);
            margin-bottom: 2px;
            font-weight: bold;
            font-size: 14px;
            font-weight: 500 !important;
            line-height: 24px;
            text-align: left;
            color: #303940;

        }

        .component {
            padding: 0;
            width: 100%;
        }
    }
}

.FRowMultiLine {
    display: flex;
    align-items: center;
    width: 100%;
    // max-width: 700px;
    // margin-bottom: 8px;

    .label {
        width: 150px;
        transform: translateY(-13px);
        line-height: 18px;

        .requiredStart {
            color: red;
        }

        .labelInput {
            border: none;
            outline: none;
            min-width: 10px;
            width: auto;
            font-weight: bold;
            font-size: 13px;
        }
    }

    .component {
        flex: 1;
        padding-left: 30px;
    }

    .desc {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .extra {
            transform: translateY(0);
            margin-bottom: 10px;
            font-weight: bold;
        }
    }

    &.vertical {
        flex-direction: column;
        align-items: flex-start;

        .label {
            width: auto;
            transform: translateY(0);
            margin-bottom: 10px;
            font-weight: bold;
        }

        .component {
            padding: 0;
            width: 100%;
        }
    }
}

.full_text {
    cursor: pointer;
    font-size: 20px;

    &:hover {
        color: #007aff;
    }
}