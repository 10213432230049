.box {
    width: 540px;
    display: flex;
    align-items: start;
    flex-direction: column;
    // padding: 24px;

    @media (max-width: 600px) {
        width: 100%;
        padding: 12px;
    }

    .close {
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;
        opacity: 0.5;
    }

    .header {
        display: flex;
        align-items: center;
        column-gap: 16px;
        padding: 24px;
        border-bottom: 1px solid #EAECF0;

        .add_user {
            background: #fff;
            border: 1px solid #EAECF0;
            box-shadow: 0px 1px 2px 0px #1018280D;
            padding: 12px;
            border-radius: 10px;
            width: 100%;
            max-width: 48px;
            height: 100%;
            max-height: 48px;
        }

        h3 {
            font-size: 18px;
            font-weight: 600;
            line-height: 28px;
            text-align: left;
            color: #101828;
        }

        p {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            text-align: left;
            color: #475467;
        }
    }

    .body {
        display: flex;
        align-items: center;
        flex-direction: column;
        // row-gap: 12px;
        padding: 32px 40px;
        width: 100%;


        .confirm_text {
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            text-align: left;
            color: #344054;
            margin-bottom: 4px;
        }

        .resend {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            text-align: left;
            color: #475467;
            margin-top: 32px;

            span {
                color: #B42318;
            }
        }

        .verification_input {
            width: 100%;
            // column-gap: 8px;
            justify-content: space-between;
            display: flex !important;
            align-items: center;

            input {
                width: 100%;
                max-width: 64px;
                min-height: 64px;
                font-size: 48px;
                font-weight: 500;
                line-height: 50px;
                letter-spacing: -0.02em;
                text-align: center;
                color: #D0D5DD;
            }
        }

    }

    .footer {
        display: flex;
        align-items: center;
        column-gap: 12px;
        padding: 24px;
        width: 100%;
        border-top: 1px solid #EAECF0;

        .save_button {
            color: #fff;
            border: 1px solid #FEF3F2;
            background: #D92D20;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            border-radius: 8px;
            width: 100%;
        }

        .cancel_button {
            color: #344054;
            background: #fff;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            border-radius: 8px;
            border: 1px solid #D0D5DD;
            box-shadow: 0px 1px 2px 0px #1018280D;
            width: 100%;
        }
    }



}