.card {
  box-shadow: none !important;
  border: 1px solid #eaecf0;
  border-radius: 12px !important;
  background-color: #ffffff;

  .MuiCardContent-root {
    padding-bottom: 0;
  }
}

.cardContent {
  padding: 16px;
}

.topRow {
  margin-bottom: 12px;
}

.iconWrapper {
  width: 48px;
  height: 48px;
  border: 1px solid #eaecf0;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}

.hotelLabel {
  font-size: 14px;
  color: #667085;
  margin: 0;
  font-weight: 500;
}

.hotelName {
  font-size: 18px;
  color: #101828;
  margin: 4px 0 0 0;
  font-weight: 600;
}

.divider {
  margin: 12px 0;
  border-color: #eaecf0;
}

.infoRow {
  display: flex;
  justify-content: space-between;
}

.infoColumn {
  flex: 1;
  &:not(:last-child) {
    margin-right: 16px;
  }
}

.label {
  font-size: 14px;
  color: #667085;
  font-weight: 500;
  margin-bottom: 4px;
}

.value {
  font-size: 16px;
  color: #101828;
  font-weight: 500;
  display: flex;
  flex-wrap: wrap;

  p {
    font-size: 16px;
    color: #101828;
    font-weight: 500;
    margin: 0;
    width: 100%;
  }
}

.actions {
  padding: 16px;
  border-top: 1px solid #eaecf0;
  display: flex;
  justify-content: flex-start;
  gap: 16px;
}

.delete_button {
  color: #b42318;
  border: 1px solid #fef3f2;
  background: #fef3f2;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  border-radius: 8px;
  width: 100%;

  &:hover {
    background: #b42318;
    color: #fef3f2;
  }
}

.edit_button {
  color: #344054;
  background: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px 0px #1018280d;
  width: 100%;
  &:hover {
    background: #344054;
    color: #fff;
    border-color: #fff;
  }
}
