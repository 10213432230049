.card {
  //   img {
  //     min-width: 400px;
  //     min-height: 350px;
  //   }
}

.galleryContainer {
  width: 100%;
  border-radius: 12px;
  box-sizing: border-box;
  max-width: 450px;
}

.customGallery {
  height: 100% !important;
  .image-gallery-slide img {
    border-radius: 8px;
  }

  .image-gallery-thumbnails-container {
    margin-top: 12px;
  }

  .image-gallery-thumbnail img {
    border-radius: 4px;
  }

  .image-gallery-left-nav,
  .image-gallery-right-nav {
    height: 100%;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.2);
  }
}

.image-gallery-icon {
  width: 36px !important;
  height: 36px !important;
}
