.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 24px;
    border-bottom: 1px solid #EAECF0;

    .settings {
        display: flex;
        align-items: center;
        column-gap: 12px;

        .panel {
            background: #fff;
            border: 1px solid #EAECF0;
            box-shadow: 0px 1px 2px 0px #1018280D;
            padding: 12px;
            border-radius: 10px;
            width: 100%;
            max-width: 48px;
            height: 100%;
            max-height: 48px;
            cursor: pointer;
        }
    }

    .content {

        display: flex;
        align-items: center;
        column-gap: 16px;
        width: 100%;


        .img_box {
            max-width: 56px;
            max-height: 56px;
            width: 100%;
            height: 100%;

            img {
                object-fit: cover;
            }
        }

        .desc {

            .name {
                font-size: 18px;
                font-weight: 600;
                line-height: 28px;
                text-align: left;
                color: #101828;
            }

            .username {
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                text-align: left;
                color: #475467;
            }
        }
    }
}

.pin {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 16px;
    border-bottom: 1px solid #EAECF0;
    width: 100%;

    .close_button {
        cursor: pointer;
    }

    .content {
        display: flex;
        align-items: center;
        column-gap: 8px;
        width: 100%;
        justify-content: space-between;

        .body {
            display: flex;
            align-items: center;
            column-gap: 6px;

            .desc {
                h4 {

                    font-size: 18px;
                    font-weight: 600;
                    line-height: 28px;
                    text-align: left;
                    text-decoration-skip-ink: none;
                    color: #101828;
                }

                p {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    text-align: left;
                    color: #475467;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    cursor: pointer;
                }


            }
        }

        svg {
            width: 100%;
            max-width: max-content;
        }
    }

    .expandMore {
        background: #fff;
        border: 1px solid #EAECF0;
        box-shadow: 0px 1px 2px 0px #1018280D;
        padding: 6px;
        border-radius: 10px;
        width: 100%;
        max-width: 36px;
        height: 100%;
        max-height: 36px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .pagination {
        display: flex;
        flex-direction: column;
        gap: 2px;
        margin-top: 10px;
        max-height: 40px;
    }

    .dot {
        width: 4px;
        height: 10px;
        border-radius: 4px;
        background-color: #FECDCA;
        cursor: pointer;
        transition: background-color 0.3s ease;
    }

    .dot.active {
        background-color: rgba(255, 0, 0, 1);
    }
}