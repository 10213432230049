.operator {
  margin-top: 16px;
  p {
    &:first-child {
      color: #6e6e6e;
      font-size: 14px;
      line-height: 18px;
    }
    &:last-child {
      color: #0c0c0c;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      margin-top: 4px;
    }
  }
}

.grid {
  display: flex;
  align-items: center;
  column-gap: 12px;
  flex-wrap: wrap;
}
.title {
  font-size: 20px;
  font-weight: 500;
  line-height: 23.44px;
  color: #00040a;
  margin-bottom: 12px !important;
}
