.ticket {
  width: 100%;
  padding: 16px;
  cursor: pointer;
  gap: 5px;

  background: #f9f9f9;

  border-radius: 12px;
  display: flex;
  align-items: center;
  transition: 0.4s ease-in-out all;
  flex-direction: column;
  border: 2px solid #f9f9f9;

  @media (max-width: 600px) {
    padding: 12px;
  }

  &:hover {
    border: 2px solid var(--primary-color);
  }

  &.onlyRead {
    border: 2px solid rgba(244, 244, 244, 1) !important;
    cursor: initial;
  }

  &.active {
    border: 2px solid var(--primary-color);
    background: none;
    background-color: #fa050928 !important;
  }

  .route {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 46px;
    margin-bottom: 16px;

    .left,
    .right {
      img {
        width: 89px;
        @media (max-width: 600px) {
          height: 16px;
          object-fit: contain;
        }
        max-width: 100px;
        max-height: 50px;
      }
    }

    .left,
    .right {
      display: flex;
      flex-direction: column;
      gap: 7px;

      h3 {
        font-size: 16px;
        font-weight: 700;
        line-height: 19.36px;
        margin: 0;

        @media (max-width: 600px) {
          font-size: 14px;
          line-height: 20px;
        }
      }

      p {
        font-size: 10px;
        font-weight: 400;
        line-height: 12.1px;
        color: rgba(130, 130, 130, 1);
        margin: 0;

        @media (max-width: 600px) {
          font-size: 13px;
        }
      }
    }

    .right {
      justify-content: flex-end;
    }

    .middle {
      display: flex;
      flex-direction: column;

      .date {
        padding-bottom: 5px;
        margin-bottom: 5px;
        border-bottom: 1px solid rgba(232, 232, 232, 1);
        display: flex;
        column-gap: 60px;
        align-items: center;
        color: rgba(90, 90, 90, 1);
        min-width: 240px;
        max-width: 280px;
        justify-content: space-between;
      }

      .iatas {
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
          color: rgba(12, 12, 12, 1);
          font-size: 12px;
          font-weight: 600;
          line-height: 20px;
          margin: 0;
        }
      }
    }
  }

  .price_form {
    display: none;
    align-items: center;
    column-gap: 20px;
    justify-content: space-between;
    width: 100%;
    border-top: 1px solid #e8e8e8;
    padding-top: 16px;

    &:last-child {
      border: none;
    }

    .row {
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      width: 100%;

      p {
        margin-bottom: 6px;
      }
    }

    .input {
      background: #fff;
      height: 28px;
      border: 0.5px solid #828282;
      border-radius: 4px;

      input {
        font-size: 12px;
        line-height: 20px;
        padding: 4px 8px;
      }
    }
  }

  .price_form_active {
    display: flex;
  }

  .client_mode {
    width: 100%;
    border-top: 1px solid #e8e8e8;
    padding-top: 16px;
    display: none;

    p {
      font-size: 12px;
      font-weight: 700;
      line-height: 20px;
      text-align: left;
      color: #667085;

      span {
        color: #000;
        font-weight: 500;
      }
    }
  }

  .client_mode_active {
    display: block;
  }
}

.ticket_for_pq {
  width: 100%;
  padding: 16px;
  cursor: pointer;
  gap: 5px;
  min-width: 500px !important;

  background: #f9f9f9;

  border-radius: 12px;
  display: flex !important;
  align-items: center;
  transition: 0.4s ease-in-out all;
  flex-direction: column;
  border: 2px solid #f9f9f9;

  @media (max-width: 600px) {
    padding: 12px;
  }

  &:hover {
    border: 2px solid var(--primary-color);
  }

  &.onlyRead {
    border: 2px solid rgba(244, 244, 244, 1) !important;
    cursor: initial;
  }

  &.active {
    border: 2px solid var(--primary-color);
    background: none;
    background-color: #fa050928 !important;
  }

  .route {
    width: 100%;
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    gap: 46px;
    margin-bottom: 16px;

    .left,
    .right {
      img {
        width: 89px;
        @media (max-width: 600px) {
          height: 16px;
          object-fit: contain;
        }
        max-width: 150px;
        height: 100%;
      }
    }

    .left,
    .right {
      display: flex !important;
      flex-direction: column;
      gap: 7px;

      h3 {
        font-size: 16px;
        font-weight: 700;
        line-height: 19.36px;
        margin: 0;

        @media (max-width: 600px) {
          font-size: 14px;
          line-height: 20px;
        }
      }

      p {
        font-size: 10px;
        font-weight: 400;
        line-height: 12.1px;
        color: rgba(130, 130, 130, 1);
        margin: 0;

        @media (max-width: 600px) {
          font-size: 13px;
        }
      }
    }

    .right {
      justify-content: flex-end;
    }

    .middle {
      display: flex !important;
      flex-direction: column;

      .date {
        padding-bottom: 5px;
        margin-bottom: 5px;
        border-bottom: 1px solid rgba(232, 232, 232, 1);
        display: flex !important;
        column-gap: 60px;
        align-items: center;
        color: rgba(90, 90, 90, 1);
        min-width: 240px;
        max-width: 280px;
        justify-content: space-between;
      }

      .iatas {
        display: flex !important;
        justify-content: space-between;
        align-items: center;

        p {
          color: rgba(12, 12, 12, 1);
          font-size: 12px;
          font-weight: 600;
          line-height: 20px;
          margin: 0;
        }
      }
    }
  }

  .price_form {
    display: none;
    align-items: center;
    column-gap: 20px;
    justify-content: space-between;
    width: 100%;
    border-top: 1px solid #e8e8e8;
    padding-top: 16px;

    &:last-child {
      border: none;
    }

    .row {
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      width: 100%;

      p {
        margin-bottom: 6px;
      }
    }

    .input {
      background: #fff;
      height: 28px;
      border: 0.5px solid #828282;
      border-radius: 4px;

      input {
        font-size: 12px;
        line-height: 20px;
        padding: 4px 8px;
      }
    }
  }

  .price_form_active {
    display: flex !important;
  }

  .client_mode {
    width: 100%;
    border-top: 1px solid #e8e8e8;
    padding-top: 16px;
    display: none;

    p {
      font-size: 12px;
      font-weight: 700;
      line-height: 20px;
      text-align: left;
      color: #667085;

      span {
        color: #000;
        font-weight: 500;
      }
    }
  }

  .client_mode_active {
    display: block;
  }
}
