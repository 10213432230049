.form {
  width: 100%;
  display: flex;
  margin-top: 15px;
  flex-direction: column;

  height: 53vh;
  overflow-y: scroll;

  div[class="DatePicker__calendarContainer"] {
    transform: scale(0.7);
    top: 0;
    left: 85%;
  }
}

.date_picker {
  position: relative;
  width: inherit;
  z-index: 999;
}

.one_way {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
  height: 100%;

  .arrival {
    position: relative;
    // z-index: 0 !important;
  }

  .departure {
    position: relative;
    // z-index: 3;
  }

  .departure,
  .arrival {
    display: flex;
    width: 100%;
    gap: 16px;

    .field {
      width: 50%;

      .label {
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        color: rgba(48, 57, 64, 1);
        margin: 0;
      }
    }
  }
}

.round_trip {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 16px;

  .arrival {
    position: relative;
    // z-index: 0 !important;
  }

  .departure {
    position: relative;
    // z-index: 3;
  }

  .departure,
  .arrival {
    display: flex;
    width: 100%;
    gap: 16px;

    .field {
      width: 50%;
      position: relative;

      .label {
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        color: rgba(48, 57, 64, 1);
        margin: 0;
      }
    }
  }
}

.datetime {
  position: relative;
  background-color: #fff !important;
  border: 0.1px solid rgba(190, 192, 193, 0.8) !important;
  padding: 0 5px;
  border-radius: 7px !important;
  z-index: 1 !important;
  width: 100%;
  height: 42px;
}

.datepicker {
  position: relative;
  background-color: #fff !important;
  border: 0.1px solid rgba(190, 192, 193, 0.8) !important;
  padding: 2px;
  border-radius: 7px !important;
  z-index: 1 !important;
  width: 100%;
  height: 42px;
}

.difficult_route {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
  height: 100%;
  padding: 4px;

  .route {
    display: flex;
    gap: 16px;

    .remove {
      margin-top: 22px;
      height: 85%;
    }

    .fields {
      display: flex;
      flex-direction: column;
      width: calc(100% - 60px);
      gap: 16px;

      .departure,
      .arrival {
        display: flex;
        width: 100%;
        gap: 16px;

        .field {
          width: 33%;

          .label {
            font-size: 14px;
            font-weight: 500;
            line-height: 24px;
            color: rgba(48, 57, 64, 1);
            margin: 0;
          }

          &.datepicker {
            div:first-child {
              width: 100% !important;
              background-color: #fff;
              border: 0.1px solid rgba(229, 233, 235, 1);
            }
          }
        }
      }
    }
  }
}