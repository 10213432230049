.lead_form {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    border: 1px solid #EAECF0;
    padding: 20px 16px;
    overflow: auto;
    height: inherit;
    border-radius: 12px;
    box-shadow: 0px 1px 2px 0px #1018280F, 0px 1px 3px 0px #1018281A;


    .input {
        background-color: #fff;
        width: 100%;

        fieldset {

            border-color: #E5E9EB !important;
        }
    }

    .lead_form {
        margin-top: 16px;
        display: flex;
        flex-direction: column;
        row-gap: 12px;

    }

    .footer {
        display: flex;
        align-items: center;
        padding-top: 16px;
        width: 100%;
        border-top: 1px solid #EAECF0;
        justify-content: space-between;


        .btn_group {
            display: flex;
            align-items: center;
            column-gap: 12px;
            width: 100%;
        }

        .save_button {
            color: #fff;
            border: 1px solid #FEF3F2;
            background: #D92D20;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            border-radius: 8px;
            width: 100%;
        }

        .cancel_button {
            color: #344054;
            background: #fff;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            border-radius: 8px;
            border: 1px solid #D0D5DD;
            box-shadow: 0px 1px 2px 0px #1018280D;
            width: 100%;
        }

    }
}