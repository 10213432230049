.searchContainer {
  width: 100%;
  background-color: #f9fafb;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  box-sizing: border-box;
  margin-top: 14px;
  position: relative;
  height: calc(100vh - 160px);
}

.backBtnWrapper {
  margin-bottom: 13px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
}

.backBtn {
  text-transform: none !important;
  border-radius: 8px !important;
  font-weight: 500 !important;
  color: #344054 !important;
  border-color: #fff !important;
  background: #fff;

  &:hover {
    background-color: #f9fafb !important;
  }
}

.searchTitle {
  font-size: 30px;
  font-weight: 600;
  line-height: 38px;
  text-align: center;
  color: #101828;
  margin-bottom: 12px;
}

.searchInputWrapper {
  width: 360px;
}

.searchInput {
  width: 100%;

  .MuiOutlinedInput-root {
    border-radius: 24px;
  }
  .MuiOutlinedInput-notchedOutline {
    border-color: #d0d5dd;
  }
}

.button {
  background: #fef3f2;
  border: 1px solid #fef3f2;
  color: #d92d20;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  border-radius: 8px;
}
