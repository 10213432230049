.box {
  width: 670px;
  display: flex;
  align-items: start;
  flex-direction: column;
  overflow: auto;
  height: 100%;
  // padding: 24px;
  @media (max-width: 992px) {
    width: 100%;
  }

  .text_area {
    padding: 20px 24px;
    width: 100%;

    @media (max-width: 992px) {
      padding: 16px;
      max-height: 500px;
      overflow: auto;
    }

    .text {
      font-size: 18px;
      font-weight: 500;
      line-height: 28px;
      color: #101828;
    }
  }

  .close {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    opacity: 0.5;
  }

  .input {
    background-color: #fff;
    width: 100%;
    padding: 16px;
    border: 1px solid #d0d5dd;
    box-shadow: 0px 1px 2px 0px #1018280d;
    border-radius: 8px;

    &:focus-visible {
      outline: none;
    }
  }

  .header {
    display: flex;
    align-items: center;
    column-gap: 16px;
    padding: 24px;
    border-bottom: 1px solid #eaecf0;
    width: 100%;

    @media (max-width: 992px) {
      padding: 16px !important;
    }

    .add_user {
      background: #fff;
      border: 1px solid #eaecf0;
      box-shadow: 0px 1px 2px 0px #1018280d;
      padding: 12px;
      border-radius: 10px;
      width: 100%;
      max-width: 48px;
      height: 100%;
      max-height: 48px;
    }

    h3 {
      font-size: 18px;
      font-weight: 600;
      line-height: 28px;
      text-align: left;
      color: #101828;
    }

    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      color: #475467;
    }
  }

  .content {
    padding: 8px;
    width: 100%;

    input {
      background: #fff;
    }
  }

  .footer {
    display: flex;
    align-items: center;
    column-gap: 12px;
    padding: 24px;
    width: 100%;
    border-top: 1px solid #eaecf0;
    justify-content: end;

    .save_button {
      color: #fff;
      border: 1px solid #fef3f2;
      background: #079455;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      border-radius: 8px;
    }

    .cancel_button {
      color: #344054;
      background: #fff;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      border-radius: 8px;
      border: 1px solid #d0d5dd;
      box-shadow: 0px 1px 2px 0px #1018280d;
    }
  }
}

.search {
  margin: 8px;
}

.date_group {
  .group_box {
    overflow: auto;

    &::-webkit-scrollbar {
      width: 8px;
      border-radius: 8px;
    }

    &::-webkit-scrollbar-track {
      background: #fff;
    }

    &::-webkit-scrollbar-thumb {
      background: #eaecf0;
      border-radius: 8px;
    }
  }
}

.loader {
  display: flex;
  align-items: center;
  padding: 8px 0;
  justify-content: center;
}

.date {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #475467;
  margin: 0 8px;
  margin-bottom: 4px;
}

.user {
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-radius: 10px;
  background-color: #fff;
  margin-bottom: 10px;
  gap: 10px;
  transition: 0.4s;
  border-radius: 8px;
  margin: 4px 8px;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;

  &:hover {
    background: #f2f4f7;
  }

  &.active {
    background: #f2f4f7 !important;
  }
}

.userInfo {
  display: flex;
  column-gap: 8px;
  align-items: center;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.details {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.userName {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #344054;
}

.platform {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #475467;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 180px;
}

.status {
  margin-top: 5px;
  background: #fef3f2;
  padding: 4px 12px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  column-gap: 6px;
  width: fit-content;

  .status_text {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    color: #b42318;
  }
}

.unassigned {
  margin-top: 5px;
  background: #eef4ff;
  padding: 4px 12px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  column-gap: 6px;
  width: fit-content;

  .dot {
    border-radius: 50%;
    width: 6px;
    height: 6px;
    background: #6172f3;
    left: 6px;
  }

  .unassigned_text {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    color: #3538cd;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 110px;
  }
}

.assigned {
  margin-top: 5px;
  background: #fef3f2;
  padding: 4px 10px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  column-gap: 6px;
  width: fit-content;

  .dot {
    border-radius: 50%;
    width: 6px !important;
    height: 6px;
    background: #b42318;
    left: 6px;
  }

  .assigned_text {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    color: #b42318;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
    text-align: left;
  }
}

.unreadTime {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  row-gap: 4px;
}

.loader_wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.unread {
  background-color: #d92d20;
  color: #fff;
  padding: 2px 8px;
  border-radius: 44px;
  font-size: 12px;
  font-weight: 400;
  line-height: var(--M);
  text-align: center;
}

.time {
  font-size: 10px;
  font-weight: 400;
  line-height: var(--L);
  text-align: left;
  color: #475467;
}
