.title {
  font-size: 20px;
  font-weight: 500;
  line-height: 23.44px;
  color: #00040a;
  margin-bottom: 12px !important;
}

.items {
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;

  @media (max-width: 992px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-radius: 0;
  }
  .item {
    display: flex;
    flex-direction: column;
    max-width: 316px;
    width: 100%;
    background: #fff;
    box-shadow: 0px 1px 2px 0px #1018280f, 0px 1px 3px 0px #1018281a;
    border-radius: 8px;

    @media (max-width: 992px) {
      max-width: 100% !important;
    }
    @media (max-width: 556px) {
      max-width: 100% !important;
    }

    .header {
      display: flex;
      align-items: center;
      padding: 16px;
      justify-content: space-between;
      border-bottom: 1px solid #e8e8e8;
      .header_title {
        color: #101828;
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
      }
      .header_text {
        color: #344054;
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
      }
    }

    .wrapper {
      padding: 16px;
      border-bottom: 1px solid #e8e8e8;
    }

    .comment {
      padding: 16px;
      justify-content: space-between;
      border-bottom: 1px solid #e8e8e8;

      & > div {
        background: #fff !important;
      }
      input {
        background: #fff !important;
      }
    }

    .info {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 12px;
      width: 100%;
      margin-bottom: 16px;
      @media (max-width: 600px) {
        margin-bottom: 8px;
      }

      .desc {
        width: 100%;
        max-width: 134px;
      }

      .key {
        font-size: 12px;
        line-height: 18px;
        color: #667085;
      }

      .name {
        font-size: 14px;
        line-height: 20px;
        color: #101828;
        font-weight: 500;
        overflow: hidden;

        @media (max-width: 600px) {
          font-size: 14px;
          width: auto;
          line-height: 20px;
        }
      }
    }

    .element {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      @media (max-width: 600px) {
        justify-content: initial;
        height: auto;
      }
      .key {
        font-size: 12px;
        line-height: 18px;
        color: #667085;
      }

      .name {
        font-size: 14px;
        line-height: 20px;
        color: #101828;
        font-weight: 500;
        overflow: hidden;

        @media (max-width: 600px) {
          font-size: 14px;
          width: auto;
          line-height: 20px;
        }
      }
    }
  }
}
