.profile {
  background: #fff;
  height: 100%;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  min-width: 0;
  width: 0;
  max-width: 300px;
  overflow: auto;
  transition: 0.3s;

  &.open {
    // width: 100%;
    min-width: 300px;
    width: 300px;
    border-left: 1px solid #eaecf0;
    transition: 0.5s;
  }

  .content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .bottom_side {
      display: flex;
      justify-content: end;
      padding: 16px;
    }
  }

  .note_button {
    background: #fff;
    border: 1px solid #d0d5dd;
    color: #344054;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    column-gap: 8px;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;

    .header_text {
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      text-align: left;
      color: #475467;
    }

    .close_icon {
      cursor: pointer;
    }
  }

  .personal_info {
    display: flex;
    align-items: center;
    column-gap: 16px;
    padding: 16px;
    flex-wrap: wrap;

    .desc {
      .name {
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        text-align: left;
        color: #101828;
      }

      .phone {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        color: #475467;
        margin: 0;
      }
    }
  }

  .assigned_agent {
    display: flex;
    align-items: start;
    flex-direction: column;
    row-gap: 8px;
    column-gap: 16px;
    padding: 16px;
    border-bottom: 1px solid #eaecf0;

    .assigned_agent_text {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      text-align: left;
      color: #475467;
    }

    .content {
      background: #fef3f2;
      padding: 8px 14px;
      display: flex;
      align-items: center;
      column-gap: 8px;
      border: 1px solid #fef3f2;
      border-radius: 8px;
      width: 100%;

      img {
        max-width: 20px;
        max-height: 20px;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .name {
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        text-align: left;
        color: #b42318;
      }
    }
  }

  .lead_section {
    width: 100%;
    transition: 0.3s;
    height: 0;
    padding: 0;
    opacity: 0;

    &.open {
      height: auto;
      opacity: 1;
      padding: 16px;
    }

    .lead_button {
      width: 100%;
      color: #344054;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      text-align: left;
    }
  }

  .quick_lead {
    transition: 0.3s;
    height: 0;
    padding: 0;
    opacity: 0;

    &.open {
      height: auto;
      opacity: 1;
      padding: 16px;
    }
  }

  .lead_list {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    padding: 16px;
    max-height: 200px;
    overflow: auto;
  }
}
