.flight {
  width: 100%;
  display: flex;
  // flex-direction: column;
  // gap: 5px;

  overflow-y: hidden;

  .flightContainer {
    overflow: hidden;
    max-height: 20px;
    width: 100%;
    display: flex;
    // display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .segment {
    width: auto;
    display: flex;
    align-items: center;
    overflow: hidden;

    .box {
      display: flex;
      align-items: center;
      // width: 40%;
      width: auto;
    }

    p {
      margin: 0;
      padding: 0;
    }

    .iataCode {
      font-size: 10px;
      font-weight: 600;
    }

    .at {
      font-size: 12px;
      font-weight: 400;
      color: #9b9b9b;
      margin-left: 5px;
    }
  }
}

.assign_button {
  background: #ff00001a;
  color: #ff0000;
  font-size: 14px;
  font-weight: 600;
  line-height: 16.94px;
  text-align: left;
  max-height: 28px;

  &:hover {
    color: #fff;
    background: #ff0000;
  }
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

.agent {
  overflow: hidden;

  .created {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}

.assigned {
  // animation: marquee 10s linear infinite;
  display: -webkit-box;
}

.passenger {
  display: flex;
  // flex-direction: column;
  align-items: center;
  justify-content: space-between;

  .popover {
    position: relative;
    display: flex;
    align-items: center;

    .popover_icon {
      cursor: help;
    }

    &:hover .popover_hover {
      opacity: 1 !important;
    }

    .popover_hover {
      position: absolute;
      min-width: 200px;
      top: 0;
      left: 100%;
      background-color: rgba(0, 0, 0, 0.4);
      width: auto;
      padding: 10px;
      color: white;
      opacity: 0;
      transition: 0.4s ease-in-out;
    }
  }

  &.man {
    svg {
      color: black;
    }
  }

  svg {
    color: var(--primary-color);
    font-size: 13px;
  }

  p {
    font-size: 10px;
    font-weight: 600;
    margin: 0;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}

.status {
  width: 100%;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 10px;

  background-color: rgba(255, 252, 194, 1);
  color: rgba(210, 148, 4, 1);

  font-size: 10px;
  font-weight: 500;
  line-height: 20px;
  text-align: justified;
  border-radius: 6px;

  &.is_confirmed {
    background-color: rgba(187, 251, 208, 1);
    color: rgba(17, 156, 43, 1);
  }
}

.text_field {
  background: #fff !important;

  fieldset {
    border: 1px solid #e5e5ea !important;
  }
}

.lead_status {
  width: 100%;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: 500;
  line-height: 20px;
  text-align: justified;
  border-radius: 6px;
}

.timezone {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 10px;
  background: #ecfdf3;
  border-radius: 16px;
  column-gap: 4px;
  margin-right: 24px;
  background: #fef3f2;

  p {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    color: #b42318;
  }

  svg {
    width: 12px;
    height: 12px;

    fill: #b42318;
  }
}

.active {
  background: #ecfdf3;

  p {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    color: #027a48;
  }

  svg {
    width: 12px;
    height: 12px;

    fill: #027a48;
  }
}

.order_popup {
  // max-width: 85%;
  // display: flex;
  align-items: center;
  justify-content: center;

  .card {
    // height: 90vh;
    overflow: auto;
  }

  .header {
    display: flex;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: sticky;
    top: 0;
    background-color: #fff;
    height: 60px;
    z-index: 99999;

    h2 {
      font-size: 22px;
      font-weight: 500;
      line-height: 28px;
    }

    svg {
      cursor: pointer;
      position: absolute;
      right: 15px;
      top: 15px;
      font-size: 20px;
      color: rgba(0, 0, 0, 1);
    }
  }
}
