.filemessage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    column-gap: 12px;
    padding: 10px 14px;
    background-color: #FCFCFD;
    border-radius: 12px;
    width: 100%;
    min-width: 280px;
    border: 1px solid #EAECF0;
    width: min-content;
    row-gap: 8px;
    max-width: 170px;

    .control {
        cursor: pointer;
        display: flex;
    }

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;

        row-gap: 2px;
        width: 100%;

        a {
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            text-align: center;
            color: #344054;
            overflow-wrap: anywhere;
        }

        .size {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            text-align: left;
            color: #475467;
        }
    }
}