.field {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  height: 100%;
  border-left: 1px solid #eaecf0;
  width: 100%;
  position: relative;
  // max-width: 850px;

  .loader {
    display: flex;
    align-items: center;
    padding: 8px 0;
    justify-content: center;
  }

  .dateSeparator {
    text-align: center;
    margin: 20px 0;
    font-size: 12px;
    color: #888;
    position: relative;

    span {
      padding: 0 10px;
      border-radius: 12px;
      display: inline-block;
    }
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 24px;
    border-top: 1px solid #eaecf0;
    column-gap: 12px;
  }

  .dragOverlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: #333;
    z-index: 100;
    border: 2px dashed #ccc;
  }
}

.reply {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 24px;
  border-top: 1px solid #eaecf0;

  .close_button {
    cursor: pointer;
  }

  .content {
    display: flex;
    align-items: center;
    column-gap: 8px;

    h4 {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      text-align: left;
      color: #344054;
    }

    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      color: #475467;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    svg {
      width: 100%;
      max-width: max-content;
    }
  }
}

.messages {
  padding: 4px 24px;
  scroll-behavior: smooth;
  transition: 0.8s;
  height: 100%;

  &::-webkit-scrollbar {
    display: none !important;
  }
}

.inputArea {
  display: flex;
  border-top: 1px solid #ddd;
  background-color: #f7f7f7;
  padding: 20px 24px;
}

input {
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

button {
  margin-left: 10px;
  padding: 10px 20px;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.scrollBottom {
  padding: 6px;
  background: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  color: #000;
  border-radius: 50%;
  border: 1px solid #eaecf0;
  max-width: 100px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: #475467;
  }
}
