.tabs {
  width: 100%;
  height: 58px;
  display: flex;
  align-items: center;
  padding: 0 24px;
  background-color: #fff;
  gap: 16px;
}

.tab {
  outline: none;
  background-color: transparent;
  border: none;
  border-bottom: 2px solid transparent;
  display: flex;
  align-items: center;
  gap: 8px;
  height: 100%;
  padding: 12px;
  background-color: #fff;
  transition: 0.4s ease-in-out all;
  cursor: pointer;

  &.active {
    border-bottom: 2px solid var(--primary-color);
  }

  .label {
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    text-align: left;
  }

  &:hover {
    opacity: 0.7;
  }
}

.badge {
  width: 32px;
  height: 32px;
  border-radius: 26px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  color: rgba(0, 4, 10, 1);
  border: 1px solid rgba(237, 237, 237, 1);
  transition: 0.4s ease-in-out all;

  &.active {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    color: #fff;
    transition: 0.4s ease-in-out all;
  }

  &.done {
    background-color: rgba(44, 195, 86, 1);
    border-color: rgba(44, 195, 86, 1);
    color: #fff;
    transition: 0.4s ease-in-out all;
  }
}
