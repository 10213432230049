.badge {
  padding: 3px 15px;
  //   border: 1px solid red;
  color: red;
  background-color: rgba(239, 180, 180, 0.422);
  border-radius: 7px;
  text-align: center;

  &.isPublished {
    // border: 1px solid green;
    color: green;
    background-color: rgba(106, 243, 106, 0.513);
  }
}
