.chat_tools {
    display: flex;
    align-items: center;
    border-radius: 12px;
    width: 100%;
    max-height: 44px;

}

.messageInput {
    flex: 1;
    background: #fff;

    .MuiOutlinedInput-root {
        background-color: white;
        border-radius: 25px;

        .MuiOutlinedInput-notchedOutline {
            border-color: transparent;
        }
    }
}

.sendButton {
    background-color: #475467;
    color: white;
    border-radius: 8px;
    border: 1px solid #475467;
    box-shadow: 0px 1px 2px 0px #1018280D;
    width: 100%;
    max-width: 44px !important;
    min-width: 44px !important;
    max-height: 44px;
    height: 100%;

    &:hover {
        background-color: #353b48;
    }
}



.recording_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 14px;
    border: 1px solid #D0D5DD;
    background: #fff;
    border-radius: 8px;
    width: 100%;
    max-height: 44px;


    .recording_trash,
    .recording {
        display: flex;
        cursor: pointer;
    }

    .timer {
        display: flex;
        align-items: center;
        column-gap: 8px;
        max-width: 80px;
        width: 100%;

        .timer_text {
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            text-align: left;
            color: #101828;

        }

        .dot {
            width: 8px;
            height: 8px;
            background: #F04438;
            display: block;
            border-radius: 50%;
        }
    }

    .cancel {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        text-align: center;
        color: #667085;
        cursor: pointer;
    }
}

.recording {
    path {
        stroke: red;
    }
}

.recordingContainer {
    display: flex;
    align-items: center;
    margin-top: 10px;
}