.navbar {
    width: 100%;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    border-bottom: 1px solid #f3f3f3;
    padding: 8px 16px;
    position: sticky;
    top: 0;

    .title_box {
        display: flex;
        align-items: center;
        column-gap: 8px;

        div {
            cursor: pointer;
            display: flex;
        }
    }

    h2 {
        font-family: Inter;
        font-size: 18px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
    }

    .session_button {
        background: #fff;
        border: 1px solid #D0D5DD;
        color: #344054;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        column-gap: 8px;
    }

    .add_contact {
        background: #FEF3F2;
        border: 1px solid #FEF3F2;
        color: #344054;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        column-gap: 8px;
        color: #B42318;
    }
}


.flex {
    display: flex;
    align-items: center;
    column-gap: 12px
}