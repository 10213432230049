.card {
  border-radius: 12px;
  background: #fff;
  padding: 24px;

  @media (max-width: 992px) {
    padding: 16px;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 16px;

    .title {
      font-size: 30px;
      line-height: 38px;
      font-weight: 600;
      color: #101828;

      @media (max-width: 992px) {
        font-size: 20px;
        line-height: 28px;
      }
    }

    svg {
      cursor: pointer;
    }
  }
}

.galleryContainer {
  width: 100%;
  border-radius: 12px;
  box-sizing: border-box;
  max-width: 450px;
}

.customGallery {
  height: 100% !important;
  .image-gallery-slide img {
    border-radius: 8px;
  }

  .image-gallery-thumbnails-container {
    margin-top: 12px;
  }

  .image-gallery-thumbnail img {
    border-radius: 4px;
  }

  .image-gallery-left-nav,
  .image-gallery-right-nav {
    height: 100%;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.2);
  }
}

.image-gallery-icon {
  width: 36px !important;
  height: 36px !important;
}
