@import "./custom.less";

:root {
  --primary-color: #fa050a;
  --background-color: #f4f4f4;
  --text-color: #828282;
  --text-black: #0c0c0c;
  --rs-bg-active: #fa050a;
}

body {
  margin: 0;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-color);
}

body::-webkit-scrollbar {
  width: 3px;
}

* {
  box-sizing: border-box !important;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Overrides */

.search-area .MuiFormControl-root {
  background-color: #fff !important;
  width: 100%;
  height: 100%;
  border: none;
  box-shadow: none;
}

.search-area .MuiFormControl-root label {
  top: 5px;
}

.search-area .MuiFormControl-root fieldset {
  border: none !important;
}

.search-area .MuiFormControl-root .MuiInputBase-root {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  box-shadow: none;
}

.search-area .MuiFormControl-root fieldset {
  width: 100%;
  height: 56px;
  border: none;
}

.MuiSelect-select {
  padding: 8px !important;
  font-size: 14px;
}

.MuiOutlinedInput-notchedOutline {
  border-color: rgba(238, 242, 244, 1) !important;
}

.MuiRadio-root,
.MuiCheckbox-root {
  padding: 0;
}

.rs-picker-toggle-wrapper {
  width: 100% !important;
  min-height: 44px !important;
  height: 100% !important;
  display: flex !important;
  color: #fff !important;
  min-width: 160px !important;
}

.rs-input-group {
  background: #fff !important;
}

#basic-menu .MuiMenu-paper {
  background: #fff2f2;
}

.rs-picker-toggle-wrapper input {
  background: #fff;
  opacity: 1 !important;
  font-size: 15px !important;
  color: #000 !important;
}

.rs-picker-toggle {
  border-radius: 8px !important;
}

.filter_date_picker span {
  color: #a7a7a7 !important;
}

/* .rs-picker-toggle input {
  background: rgba(238, 242, 244, 1) !important;
  opacity: 1 !important;
  font-size: 16px !important;
  color: #000 !important;
} */

.rs-picker-default:not(.rs-picker-disabled) .rs-btn-active,
.rs-picker-default:not(.rs-picker-disabled) .rs-btn:focus,
.rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle-active,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:focus,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover {
  border-color: var(--primary-color);
}

.rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
.rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
  top: 11px;
}

.rs-picker-toggle input::placeholder {
  color: #a7a7a7 !important;
  font-weight: 500;
}

.rs-picker-toggle .rs-stack {
  height: 100%;
}

.rs-picker-toggle .rs-picker-toggle-placeholder {
  color: #a7a7a7;
}

.disabled {
  opacity: 0.5;
}

.DatePicker {
  width: 100%;
}

#dashboard_chart .MuiTabs-root {
  margin-bottom: 10px;
  border: none;
}

.input {
  background: #fff !important;

  fieldset {
    border: 1px solid #e5e5ea !important;
  }
}

#dashboard_chart .MuiTab-root {
  border: none;
  padding: 8px 12px;
  color: #667085;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}

#chat-input .MuiInputBase-root {
  height: 100% !important;
  min-height: 44px !important;
  padding: 10px 14px;
}
#chat-input .MuiInputBase-root textarea {
  max-height: 300px !important;
  overflow: auto !important;
}

#modal-input .MuiInputBase-root {
  height: 100% !important;
  min-height: 44px !important;
  padding: 10px 14px;
}
#modal-input .MuiInputBase-root textarea {
  max-height: 300px !important;
  overflow: auto !important;
}

#dashboard_chart .Mui-selected {
  border: none;
  padding: 8px 12px;
  color: #344054;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  background: #f9fafb;
  border-radius: 6px;
}

#order_tabs .MuiTab-root {
  background: #fff;
  padding: 8px 12px !important;
  min-height: auto !important;
  margin: 0 4px;
  border-radius: 6px;
  color: #667085;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}

#order_tabs .Mui-selected {
  background: #fef3f2;
  padding: 8px 12px !important;
  min-height: auto !important;
  margin: 0 4px;
  border-radius: 6px;
  color: #b42318;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}

.DatePicker input {
  width: 100%;
  text-align: left;
  font-size: 16px;
  font-family: "Inter", sans-serif;
  border-radius: 8px;
  background-color: #fff;
  border: none;
  outline: none;
  color: #262626;
  font-weight: 500;
  z-index: initial;
  position: relative;
}

.DatePicker input::placeholder {
  color: #262626;
}

.date_time-picker .MuiFormControl-root {
  background: #fff;
}
.date_time-picker fieldset {
  border: none;
}

#session-simple-popover .MuiPaper-root {
  box-shadow: none !important;
  border: 1px solid #eaecf0;
  box-shadow: 0px 4px 6px -2px #10182808, 0px 12px 16px -4px #10182814;
  border-radius: 8px;
}

#chat_sidebar .Mui-focused {
  box-shadow: none !important;
}
#chat_sidebar .MuiFormControl-root:focus-visible {
  outline: none !important;
}

#scrollableDiv::-webkit-scrollbar {
  width: 8px;
}

#scrollableDiv::-webkit-scrollbar-thumb {
  background: #eaecf0;
  border-radius: 8px;
}

.highlighted::after {
  content: "";
  background-color: rgba(255, 0, 0, 0.2);
  animation: pulse 0.7s infinite;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 12px;
}

@keyframes pulse {
  0% {
    border-color: rgba(255, 0, 0, 0.2);
  }

  50% {
    border-color: transparent;
  }

  100% {
    border-color: rgba(255, 0, 0, 0.2);
  }
}

.infinite-scroll-component__outerdiv ::-webkit-scrollbar {
  display: none !important;
}

.image-gallery-icon {
  width: 36px !important;
  height: 36px !important;
  padding: 8px;
  border-radius: 50%;
  background: #ffffff99;
  filter: none;
}

.image-gallery-right-nav {
  right: 16px !important;
}
.image-gallery-left-nav {
  left: 16px !important;
}
.image-gallery-icon svg {
  height: 20px !important;
  width: 20px !important;
  stroke: #344054 !important;
}

#hotel-gallery .image-gallery-image {
  min-height: 300px !important;
  max-height: 300px !important;
  object-fit: cover !important;
}

#hotel-gallery-pop .image-gallery-image {
  min-height: 600px !important;
  max-height: 600px !important;
  width: 800px !important;
  object-fit: contain !important;
  background: #fff;
  padding: 10px;
  border-radius: 12px;
}

@media (max-width: 566px) {
  .image-gallery-right-nav {
    right: 4px !important;
  }
  .image-gallery-left-nav {
    left: 4px !important;
  }

  #hotel-gallery-modal .image-gallery-image {
    max-height: 422px !important;
    width: 100% !important;
    object-fit: cover !important;
    border-radius: 12px;
  }
}
.image-gallery-icon svg {
  height: 20px !important;
  width: 20px !important;
  stroke: #344054 !important;
}

#hotel-gallery-modal .image-gallery-image {
  max-height: 422px !important;
  width: 872px;
  object-fit: cover !important;
  border-radius: 12px;
}

.image-gallery-thumbnail-image {
  max-width: 108px !important;
  min-height: 80px !important;
  max-height: 80px !important;
  object-fit: cover !important;
}

#insurance .MuiSlider-root {
  height: 16px !important;
  border-radius: 0;
  pointer-events: none;
}

@media (max-width: 992px) {
  #insurance .MuiSlider-root {
    width: 100% !important;
    margin: 0 16px !important;
    margin-bottom: 16px !important;
    max-width: -webkit-fill-available !important;
  }
}

#insurance .MuiSlider-thumb::after {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  border: 2px solid #ffffff;
}
#insurance .MuiSlider-thumb {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  border: 2px solid #ffffff;
}
#insurance .MuiSlider-mark {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  background: #d0d5dd;
  transform: translateX(-14px) translateY(-13.5px);
  z-index: 999;
}
#insurance .MuiSlider-markActive {
  width: 26px;
  border-radius: 50%;
  height: 26px;
  background: #fa050a;
  opacity: 1;
}
#insurance .MuiSlider-rail {
  background: #d0d5dd;
}
