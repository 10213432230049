.main {
  background: #f8f8f8;
  padding: 48px 40px;

  @media (max-width: 992px) {
    padding: 48px 32px;
  }

  @media (max-width: 566px) {
    padding: 32px 16px;
  }
  .container {
    display: flex;
    column-gap: 32px;
    padding: 0;

    @media (max-width: 992px) {
      flex-direction: column;
      row-gap: 32px;
    }
    @media (max-width: 566px) {
      row-gap: 24px;
    }
    .box {
      width: 75%;
      max-width: 980px;
      display: flex;
      flex-direction: column;
      column-gap: 16px;
      row-gap: 32px;
      @media (max-width: 992px) {
        width: 100%;
      }
    }
    .payment {
      width: 26%;
      max-width: 100%;
      position: relative;
      @media (max-width: 992px) {
        width: 100%;
        max-width: 100%;
      }
    }
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
  }
}

button {
  &:disabled {
    opacity: 0.7;
    cursor: not-allowed !important;
  }
}
