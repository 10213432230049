.image {
    width: 100%;
    height: 100%;
    max-width: 300px;
    max-height: 200px;
    border-radius: 12px;

    img {
        object-fit: cover;
    }

    &.merged {
        padding: 8px;
        border-radius: 10px 10px 0 0;
        background: #fef5f5;
    }

    &.other {
        background: #fff !important;
        border: 1px solid #EAECF0 !important;
        border-bottom: none !important;
    }
}

.url_image {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    border-radius: 12px;

    img {
        object-fit: cover;
    }

    &.merged {
        border-radius: 10px 10px 0 0;
        background: #fef5f5;
        margin-bottom: 12px;
    }

    &.other {
        background: #fff !important;
        border: none !important;

    }
}