.box {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 24px;

    @media (max-width: 600px) {
        width: 100%;
        padding: 12px;
    }

    .close {
        position: absolute;
        right: 5px;
        top: 5px;
        cursor: pointer;
        opacity: 0.5;
    }

    .field {
        width: 100%;
        margin-top: 16px;
        margin-bottom: 16px;
    }

    .button_box {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 12px;

        button {
            border-radius: 8px;
            padding: 8px 24px;
        }
    }
}