.navbar {
  width: 100%;
  height: 52px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border-bottom: 1px solid #f3f3f3;
  padding: 8px 16px;

  h2 {
    font-family: Inter;
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
  }
}

.order_tabs {
  display: flex;
  align-items: center;
}

.sidebar {
  width: 240px;
  height: 100vh;
  background-color: #fff;
  border-right: 1px solid #f3f3f3;
  position: sticky;
  bottom: 0;
  display: flex;
  flex-direction: column;

  .header {
    width: 100%;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #ececec;

    img {
      object-fit: contain;
    }
  }

  .main {
    display: flex;
    width: 100%;
    flex-direction: column;
    height: calc(100vh - 52px);
    justify-content: space-between;

    .elements {
      display: flex;
      width: 100%;
      flex-direction: column;

      .activeNavLink {
        background-color: var(--background-color) !important;

        svg {
          color: var(--primary-color) !important;
        }

        .label {
          color: #0c0c0c !important;
        }
      }

      .navLink,
      .activeNavLink {
        width: 100%;
        height: 52px;
        display: flex;
        align-items: center;
        padding: 0 24px;
        text-decoration: none;
        margin-top: 12px;

        svg {
          color: var(--text-color);
        }

        .label {
          font-size: 16px;
          line-height: 20px;
          font-weight: 500;
          color: var(--text-color);
          margin-left: 12px;
        }
      }
    }

    .profile {
      display: flex;
      cursor: pointer;
      height: 84px;
      width: 100%;
      align-items: center;
      padding: 0 16px;
      border-top: 1px solid #f4f4f4;
      text-transform: none;

      img {
        width: 52px;
        height: 52px;
        border-radius: 50%;
        object-fit: cover;
      }

      h3 {
        font-family: Inter;
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
        color: #0c0c0c;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      h4 {
        font-family: Inter;
        font-size: 13px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: left;
        color: var(--text-color);
      }

      svg {
        color: var(--text-color);
      }
    }
  }
}

.container {
  width: 100%;
  display: flex;
  overflow: hidden;
  position: relative;

  .wrapper {
    display: flex;
    flex-direction: column;
    width: calc(100vw - 240px);
    position: relative;

    .content {
      width: 100%;
      height: calc(100vh - 52px);
      overflow-y: scroll;
      overflow-x: hidden;
      // position: relative;
    }
  }
}