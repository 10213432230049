.field {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    height: 100%;
    border-left: 1px solid #EAECF0;
    width: 100%;
    position: relative;

    // max-width: 850px;
    .header {
        padding: 18px 16px;
        border-bottom: 1px solid #EAECF0;

        .content {
            display: flex;
            align-items: center;
            column-gap: 12px;

            .icon {
                display: flex;
                cursor: pointer;
            }

            p {
                font-size: 18px;
                font-weight: 600;
                line-height: 28px;
                text-align: left;
                text-decoration-skip-ink: none;
                color: #101828;
            }
        }
    }

    .emptyState {
        text-align: center;
        margin: 20px 0;
        font-size: 1rem;
        color: gray;
    }

    .pinnedMessages {
        height: 100%;
        overflow: auto;
        padding: 10px;

        &::-webkit-scrollbar {
            display: none !important;
        }

        .loader {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 10px 0;
        }

        .dateSeparator {
            text-align: center;
            margin: 10px 0;
            font-size: 0.85rem;
            color: gray;
        }

        .scrollBottom {
            position: absolute;
            bottom: 10px;
            right: 10px;
            cursor: pointer;
        }
    }
}