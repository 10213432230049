.card {
  padding: 16px;
  background-color: #fff;
  border-radius: 12px;
  @media (max-width: 600px) {
    padding: 12px;
  }
  h4 {
    margin-bottom: 12px !important;
    color: #00040a;
    font-size: 20px;
    font-weight: 500;
    @media (max-width: 600px) {
      font-size: 18px;
      line-height: 24px;
    }
  }
}
