.popover_container {
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 2px;
  padding: 6px;

  .wrapper {
    background: #fff;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    row-gap: 2px;

    .button {
      width: 100%;
      border-radius: 6px !important;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: start;
      column-gap: 8px;
      padding: 8px;
      color: #101828;

      svg {
        color: #667085;
      }

      &:hover {
        background: #fef3f2;
        color: #d92d20;

        svg {
          color: #d92d20;

          path {
            stroke: #d92d20;
          }
        }
      }
    }

    .forward {
      transform: rotateY(180deg);
    }
  }
}

.save_button {
  color: #fff;
  border: 1px solid #fef3f2;
  background: #d92d20;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  border-radius: 8px;
  width: 50%;
}

.cancel_button {
  color: #344054;
  background: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px 0px #1018280d;
  width: 50%;
}
