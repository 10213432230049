.dropdown {
  display: flex;
  align-items: center;
  justify-content: start;
  column-gap: 8px;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  padding: 16px;
  border-radius: 8px;
  font-size: 16px;
  line-height: 24px;
  color: #101828;
  font-weight: 500;
  margin-bottom: 12px;
}
.menu {
  box-shadow: 0px 20px 25px 0px #00000040;
  padding: 12px;
  margin-top: 8px;
  border-radius: 8px;
  background: #fff;
  width: 100%;
  z-index: 10;
  position: absolute;
  left: 0;
  top: 100%;
}

.option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  row-gap: 16px;
  padding: 8px;

  p {
    font-size: 16px;
    color: #101828;
    font-weight: 600;
  }

  .icon {
    display: flex;
    cursor: pointer;
    padding: 4px;
    width: 28px;
    height: 28px;
  }
}
