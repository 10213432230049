.title {
  font-size: 20px;
  font-weight: 500;
  line-height: 23.44px;
  color: #00040a;
  margin-bottom: 12px !important;
}

.images {
  background: #fff;
  box-shadow: 0px 1px 2px 0px #1018280f, 0px 1px 3px 0px #1018281a;
  border-radius: 8px;
  padding: 16px;
  gap: 10px;
  display: flex;

  @media (max-width: 566px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
  }
}

.imageContainer {
  position: relative;
  width: 181px;
  height: 171px;
  overflow: hidden;

  @media (max-width: 992px) {
    width: 100%;
  }
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  @media (max-width: 566px) {
    max-height: 150px;
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 566px) {
    max-height: 150px;
  }
}

.overlayText {
  font-size: 50px;
  line-height: 59px;
  font-weight: 500;
  color: #fff;
}
