.accordion {
  display: flex;
  flex-direction: column;

  .header {
    padding: 12px 16px 12px 16px;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;
    border-bottom: 1px solid rgba(237, 237, 237, 1);
    background: rgba(249, 249, 249, 1);

    cursor: pointer;
    outline: none;

    h3 {
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
    }

    .actions {
      display: flex;
      align-items: center;
      gap: 20px;
    }
  }
}
