.hotelSection {
  width: 100%;
  height: calc(100vh - 144px);
  padding: 20px;
  box-sizing: border-box;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  .title {
    font-size: 30px;
    font-weight: 600;
    line-height: 38px;
    text-align: left;
  }
}

.addHotelButton {
  background-color: #00b894;

  &:hover {
    background-color: #009a80;
  }
}

.button {
  background: #fef3f2;
  border: 1px solid #fef3f2;
  color: #d92d20;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  border-radius: 8px;
}
