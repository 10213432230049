.ticket {
  width: 100%;
  padding: 12px;
  cursor: pointer;
  gap: 5px;

  background: linear-gradient(0deg, #d2d2d2, #c7c7c777),
    linear-gradient(0deg, #f9f9f9, #f9f9f9);

  border-radius: 12px;
  display: flex;
  align-items: center;
  transition: 0.4s ease-in-out all;
  flex-direction: column;
  border: 2px solid #f9f9f9;

  @media (max-width: 600px) {
    padding: 12px;
  }
  &:hover {
    border: 2px solid var(--primary-color);
  }
  &.onlyRead {
    border: 2px solid rgba(244, 244, 244, 1) !important;
    cursor: initial;
  }

  &.active {
    border: 2px solid var(--primary-color);
    background: none;
    background-color: #fa050928 !important;
  }

  .route {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 32px;
    .left,
    .right {
      img {
        width: 89px !important;
        @media (max-width: 600px) {
          height: 30px;
          object-fit: contain;
        }
      }
    }

    .left,
    .right {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 7px;
      width: 34%;

      h3 {
        font-size: 24px;
        font-weight: 500;
        line-height: 24px;
        margin: 0;
        @media (max-width: 600px) {
          font-size: 14px;
          line-height: 20px;
        }
      }

      p {
        font-size: 13px;
        font-weight: 400;
        line-height: 13px;
        color: rgba(130, 130, 130, 1);
        margin: 0;
        @media (max-width: 600px) {
          font-size: 13px;
        }
      }
    }

    .right {
      justify-content: flex-end;
    }

    .middle {
      width: 32%;
      display: flex;
      flex-direction: column;

      .date {
        padding-bottom: 5px;
        margin-bottom: 5px;
        border-bottom: 1px solid rgba(232, 232, 232, 1);
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: rgba(90, 90, 90, 1);
      }

      .iatas {
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
          color: rgba(12, 12, 12, 1);
          font-size: 12px;
          font-weight: 600;
          line-height: 20px;
          margin: 0;
        }
      }
    }
  }
}
