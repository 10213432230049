.wrapper {
  display: flex;

  .sidebar {
    width: 220px;
    height: calc(100vh - 54px);
    background-color: #fff;
    border-right: 1px solid #f3f3f3;

    .list {
      margin: 0;
      padding: 0;
      list-style: none;
      width: 100%;

      .item {
        height: 50px;
        width: 100%;
        display: flex;
        align-items: center;
        padding: 0 20px;
        font-size: 16px;
        font-weight: 500;
        transition: 0.4s ease-out all;
        cursor: pointer;
        gap: 10px;

        svg {
          transition: 0.4s ease-in-out all;
          font-size: 21px;
        }

        &.active,
        &:hover {
          background-color: var(--background-color);

          svg {
            color: var(--primary-color);
          }
        }
      }
    }
  }

  .content {
    width: calc(100% - 220px);
  }
}
