.order_popup {
  // max-width: 85%;
  // display: flex;
  align-items: center;
  justify-content: center;

  .card {
    overflow: auto;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    max-height: 95%;
    .content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      padding: 24px;
    }

    h5 {
      font-size: 22px;
      font-weight: 500;
      line-height: 28px;
      color: #000;
      text-align: center;
    }
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    padding: 24px;
  }

  .header {
    display: flex;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 999;
    padding: 24px;

    h2 {
      font-size: 22px;
      font-weight: 500;
      line-height: 28px;
    }

    svg {
      cursor: pointer;
      position: absolute;
      right: 15px;
      top: 15px;
      font-size: 20px;
      color: rgba(0, 0, 0, 1);
    }
  }
}
