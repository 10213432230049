.previewItem {
    position: relative;
    width: 100%;
    overflow: hidden;
    border-radius: 5px;
    border: 1px solid #ccc;
    max-height: 160px;
}

.video {
    width: 100%;
    height: 100%;
    max-width: 600px;
    max-height: 400px;

    div {
        max-width: 600px !important;
        height: 100% !important;
    }

    video {
        border-radius: 16px !important;
    }
}

.previewImage {
    width: 100%;
    height: 100%;
    object-fit: contain;
    transition: transform 0.3s ease;
}

.previewImage:hover {
    transform: scale(1.1);
}

.closeButton {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    padding: 5px;
    z-index: 10;
    width: 100%;
    max-width: 24px;
    max-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loadingSpinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;
}