.box {
  width: 421px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 24px;
  @media (max-width: 600px) {
    width: 100%;
    padding: 12px;
  }
  .close {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    opacity: 0.5;
  }
  p {
    color: #323232;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin: 24px 0;
  }
  .buttons {
    display: flex;
    gap: 16px;
    width: 100%;
    button {
      width: 50%;
      padding: 12px 16px;
      cursor: pointer;
      border: none;
      border-radius: 8px;
      font-size: 16px;
      font-weight: 500;
      line-height: 18px;
      @media (max-width: 600px) {
        font-size: 14px;
        line-height: 22px;
      }
      &:first-child {
        background: #f2f2f3;
        color: #00040a;
      }
      &:last-child {
        background: #d90506;
        color: #fff;
      }
    }
  }
}
