.computed {
  display: flex;
  align-items: end;
  column-gap: 8px;
  margin-bottom: 20px;
}

.message {
  display: flex;
  flex-direction: column;
}

.video {
  width: 100%;
  height: 100%;
  max-width: fit-content;
  max-height: 180px;

  div {
    max-width: max-content !important;
    max-height: 180px !important;
  }

  video {
    border-radius: 16px !important;
    max-height: inherit;
  }

  &.merged {
    padding: 8px;
    border-radius: 10px 10px 0 0;
    background: #fef5f5;
  }

  &.other {
    background: #fff !important;
    border: 1px solid #eaecf0 !important;
    border-bottom: none !important;
  }
}

.self {
  align-self: flex-start;
  width: fit-content;
  margin-left: auto;
  // max-width: 50%;
  position: relative;

  &:hover {
    .share {
      opacity: 1;
    }
  }

  .share {
    position: absolute;
    right: 100%;
    top: 60%;
    transform: translateY(-50%);
    cursor: pointer;
    transition: 0.4s;
    width: 100%;
    height: 100%;
    max-width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
  }

  .body {
    padding: 10px;
    border-radius: 10px;
    background-color: #fef5f5;
    border-top-right-radius: 0;
    width: 100%;
    max-width: 300px;
    overflow-wrap: anywhere;

    &.merged {
      border-radius: 0 0 10px 10px;
    }

    .content {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      color: #b42318;
      overflow-wrap: break-word;
    }

    .contact {
      .content {
        padding: 8px 12px;
        background: #fef3f2;
        border-left: 4px solid #d92d20;
        border-radius: 4px;
        display: flex;
        align-items: center;
        column-gap: 12px;
        cursor: pointer;

        .name {
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          text-align: left;
          color: #b42318;
        }

        .phone {
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          text-align: left;
          color: #475467;
        }
      }
    }

    .reply {
      border-left: 4px solid #475467;
      background: #f9fafb;
      padding: 4px;
      border-radius: 4px;
      width: 100%;
      height: 100%;
      max-width: 300px;
      // max-height: 180px;
      margin-bottom: 8px;
      display: block;

      p {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
  }

  .url_content {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-decoration: underline;
    text-align: left;
    color: #b42318;
  }

  .url_text {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #b42318;
  }
}

.missed_call {
  display: flex;
  align-items: start;
  column-gap: 16px;
  padding: 10px 14px;
  border-radius: 8px;
  background: #fef3f2;
  width: 100%;
  height: 100%;
  min-width: 272px;
  min-height: 60px;

  .icon {
    padding: 6px;
    width: 32px;
    height: 32px;
    border-radius: 6px;
    background: #fee4e2;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .content {
    p {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      text-align: left;
      color: #b42318;
    }

    .date {
      display: flex;
      align-items: center;
      column-gap: 4px;

      p {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        color: #d92d20;
      }
    }
  }
}

.call {
  display: flex;
  align-items: start;
  column-gap: 16px;
  padding: 10px 14px;
  border-radius: 8px;
  background: #fef3f2;
  width: 100%;
  height: 100%;
  min-width: 272px;
  min-height: 60px;

  .icon {
    padding: 6px;
    width: 32px;
    height: 32px;
    border-radius: 6px;
    background: #fee4e2;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .content {
    p {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      text-align: left;
      color: #b42318;
    }

    .date {
      display: flex;
      align-items: center;
      column-gap: 4px;

      p {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        color: #039855;
      }
    }
  }
}

.other {
  width: fit-content;
  // max-width: 50%;
  position: relative;

  &:hover {
    .share {
      opacity: 1;
    }
  }

  .share {
    position: absolute;
    left: 100%;
    top: 60%;
    transform: translateY(-50%);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.4s;
    width: 100%;
    height: 100%;
    max-width: 50px;
    opacity: 0;
  }

  .body {
    padding: 10px;
    border-radius: 10px;
    border-top-left-radius: 0;
    background-color: #fff;
    border: 1px solid #eaecf0;
    width: 100%;
    max-width: 300px;
    overflow-wrap: anywhere;

    &.merged {
      border-radius: 0 0 10px 10px;
      border-top: none;
    }

    .reply {
      border-left: 4px solid #d92d20;
      background: #fef3f2;
      padding: 4px;
      border-radius: 4px;
      width: 100%;
      height: 100%;
      max-width: 300px;
      // max-height: 200px;
      margin-bottom: 8px;
      display: block;

      p {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }

    .content {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      color: #101828;
      overflow-wrap: break-word;
    }

    .contact {
      .content {
        padding: 8px 12px;
        background: #fef3f2;
        border-left: 4px solid #d92d20;
        border-radius: 4px;
        display: flex;
        align-items: center;
        column-gap: 12px;
        cursor: pointer;

        .name {
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          text-align: left;
          color: #b42318;
        }

        .phone {
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          text-align: left;
          color: #475467;
        }
      }
    }
  }
}

.header {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  margin-bottom: 5px;
  column-gap: 8px;
  align-items: center;

  .time {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: #475467;
  }

  .sender {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    color: #475467;
  }
}

.file {
  background-color: #e6f7ff;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #91d5ff;
  color: #0050b3;
}
